import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "wellbore";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M0.7 15.7C0.9 15.5 1.2 15.5 1.4 15.7L2.3 16.8C3 17.6 4.3 17.6 5 16.8L5.6 16.1C6 15.6 6.7 15.7 7.1 16.2C8 17.6 10.1 17.6 11 16.2C11.3 15.7 12.1 15.6 12.5 16.1L12.9 16.6C13.7 17.5 15.1 17.5 16 16.6L16.8 15.7C17 15.5 17.3 15.5 17.5 15.7C17.7 15.9 17.7 16.2 17.5 16.4L16.6 17.2C15.4 18.6 13.3 18.6 12 17.2L11.7 16.8C10.4 18.7 7.6 18.7 6.3 16.8L5.8 17.4C4.7 18.7 2.7 18.7 1.6 17.4L0.7 16.3C0.4 16.2 0.5 15.8 0.7 15.7Z M17 9H12.4L11.1 1H11.5C11.8 1 12 0.8 12 0.5C12 0.2 11.8 0 11.5 0H6.5C6.2 0 6 0.3 6 0.5C6 0.7 6.2 1 6.5 1H6.9L5.6 9H4V6C4 5.2 3.6 5 3 5H1C0.4 5 0 5.4 0 6V11C0 11.6 0.4 12 1 12H2V13C2 13.6 2.4 14 3 14H4C4.6 14 5 13.6 5 13V12H13V13C13 13.6 13.4 14 14 14H15C15.6 14 16 13.6 16 13V12H17C17.6 12 18 11.6 18 11V10C18 9.4 17.6 9 17 9ZM7.77 9L10.91 6.2L11.44 9H7.77ZM10.7 4.91L8 4.3L10.2 2L10.7 4.91ZM7.9 1H9.8L7.6 3.2L7.9 1ZM7.2 5.1L9.9 5.7L6.6 8.7L7.2 5.1ZM1 5.99H3V7.01H1V5.99ZM1 7.99H3V9.01H1V7.99ZM17 11H15V13H14V11H4V13H3V11H1V10H17V11Z";
const prefix = "fac";

export const Wellbore: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  // @ts-expect-error we're using a custom name.
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(Wellbore);
