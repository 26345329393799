import * as Sentry from "@sentry/react";
import { ThemeWrapper } from "library-ui-components";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserAuthWrapper } from "library-frontend-utils/auth";
import config from "../deploy";
import { ErrorPage, LoadingPage } from "library-ui-components/pages";
import PostHogWrapper from "library-frontend-utils/posthog";

const queryClient = new QueryClient();

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
      <BrowserAuthWrapper config={config}>
        <PostHogWrapper>
          <QueryClientProvider client={queryClient}>
            <ThemeWrapper>
              <RouterProvider
                router={router}
                fallbackElement={<LoadingPage />}
              />
              <ReactQueryDevtools initialIsOpen={false} />
            </ThemeWrapper>
          </QueryClientProvider>
        </PostHogWrapper>
      </BrowserAuthWrapper>
    </Sentry.ErrorBoundary>
  );
}

export default App;
