import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "meters-day";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData = `M9,11c0,0.3,0.3,0.6,0.6,0.6v1.7c0,0.1,0.1,0.3,0.1,0.4l2,2c0.2,0.2,0.5,0.2,0.7,0l2-2c0.1-0.1,0.1-0.2,0.1-0.4
v-1.7c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6V8.6c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6V4.5c0-0.3-0.2-0.5-0.5-0.5h-4
C9.8,4,9.6,4.2,9.6,4.5v2.9C9.3,7.4,9,7.7,9,8s0.3,0.6,0.6,0.6v1.8C9.3,10.4,9,10.7,9,11z M10.8,5.2h2.6v2.2h-2.6V5.2z M10.8,8.6
h2.6v1.8h-2.6V8.6z M10.8,11.6h2.6V13l-1.3,1.3L10.8,13V11.6z M17.6,7.4H16v5.1c0,2.2-1.7,3.9-3.9,3.9c-2.2,0-3.9-1.7-3.9-3.9V7.4
H6.6C4.6,7.4,3,9,3,11v6c0,2,1.6,3.6,3.6,3.6h11c2,0,3.6-1.6,3.6-3.6v-6C21.2,9,19.6,7.4,17.6,7.4z M20,17c0,1.3-1.1,2.4-2.4,2.4
h-11c-1.3,0-2.4-1.1-2.4-2.4v-6c0-1.3,1.1-2.4,2.4-2.4H7v3.9c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1V8.6h0.4
c1.3,0,2.4,1.1,2.4,2.4V17z`;
const prefix = "fac";

export const MetersDay: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  // @ts-expect-error we're using a custom name.
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(MetersDay);
