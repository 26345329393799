type ValidURL =
  | `https://tooljet.t60.io/tools/applications/${string}`
  | `https://${"tds" | "tds-dev" | "tds-test"}.varenergi.no/app/${string}`
  | `https://app.powerbi.com/${string}`
  | `/${string}`;

type FrontendApp = {
  name: string;
  description: string;
  roles: string[];
  url: {
    local: ValidURL;
    dev?: ValidURL;
    prod?: ValidURL;
    t60dev?: ValidURL;
    t60prod?: ValidURL;
    varenergidev?: ValidURL;
    varenergitest?: ValidURL;
    varenergiprod?: ValidURL;
  };
};

const frontendApps: FrontendApp[] = [
  {
    name: "Bottom Hole Assembly",
    description:
      "Browse, filter, and review BHA components to plan better wells and discuss with service providers.",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-bha",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-bha",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-bha",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-bha",
    },
  },
  {
    name: "Cementing",
    description:
      "Quickly access reports to review historical cement jobs and optimize upcoming work.",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-cementing",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-cementing",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-cementing",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-cementing",
    },
  },
  {
    name: "Drilling Performance",
    description:
      "Reporting of key well delivery and execution KPIs for the Drilling & Wells department.",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-drilling-performance",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-drilling-performance",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-drilling-performance",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-drilling-performance",
    },
  },
  {
    name: "Duty Handover",
    description:
      "Manage when personnel are going on/off duty, review well status updates and other critical information.",
    roles: ["basic", "extended", "admin"],
    url: {
      local: "/duty-handover",
      dev: "/duty-handover",
      varenergidev: "/duty-handover",
      varenergitest: "/duty-handover",
      varenergiprod: "/duty-handover",
    },
  },
  {
    name: "Executive Outlook",
    description:
      "Monitor rigs and wellbores operated by Vår Energi based on corporate KPIs and performance indicators.",
    roles: ["admin", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-executive-outlook",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-executive-outlook",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-executive-outlook",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-executive-outlook",
    },
  },
  {
    name: "Formations",
    description:
      "Edit and manage the formations for each wellbore in the database.",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-formations",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-formations",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-formations",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-formations",
    },
  },
  {
    name: "Geomechanics",
    description:
      "Drill more efficient wells by leveraging geomechanical data to perform offset well analysis and geomechanical modeling.",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-geomechanics",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-geomechanics",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-geomechanics",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-geomechanics",
    },
  },
  {
    name: "Mud Tracking",
    description:
      "Review reports and track the quality of the mud used during drilling operations for any wellbore.",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-mud-tracking",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-mud-tracking",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-mud-tracking",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-mud-tracking",
    },
  },
  {
    name: "Mud Tracking Upload",
    description: "Upload reports and configure the Mud Tracking application",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/mud-tracking-upload/upload-xml-66729638d452c40c7c8108b8",
      varenergidev:
        "https://tds-dev.varenergi.no/app/mud-tracking-upload/upload-xml-66729638d452c40c7c8108b8",
      varenergitest:
        "https://tds-test.varenergi.no/app/mud-tracking-upload/upload-xml-667c1b45a0de7a081f6126f8",
      varenergiprod:
        "https://tds.varenergi.no/app/mud-tracking-upload/upload-xml-6544bcd6bed22c49e2792525",
    },
  },
  {
    name: "Source Mapper",
    description:
      "Map source data from Kabal, Wellview, and iQx to a given wellbore",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/source-mapper/kabal-wellbores-667298a0d452c40c7c81095c",
      varenergidev:
        "https://tds-dev.varenergi.no/app/source-mapper/kabal-wellbores-667298a0d452c40c7c81095c",
      varenergitest:
        "https://tds-test.varenergi.no/app/source-mapper/kabal-wellbores-667c1bf1a0de7a081f612732",
      varenergiprod:
        "https://tds.varenergi.no/app/source-mapper/kabal-wellbores-65017df875e59933bb64329a",
    },
  },
  {
    name: "Survey Benchmarking",
    description:
      "Verify the planned wellbore wellpath by uploading candidate survey plans and checking DDI and MTI against offset wellbores to minimize non-productive time (NPT).",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-design-envelope",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-design-envelope",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-design-envelope",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-design-envelope",
    },
  },
  {
    name: "Survey",
    description:
      "Streamlined access to wellbore survey data for operational monitoring and decision-making: tabular view, 2D and 3D plots of wellbore.",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-survey-app",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-survey-app",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-survey-app",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-survey-app",
    },
  },
  {
    name: "Upload AFE Data",
    description: "Upload the planned time depth curve data for each wellbore",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/upload-afe-data/upload-afe-data-6672997cd452c40c7c8109ce",
      varenergidev:
        "https://tds-dev.varenergi.no/app/upload-afe-data/upload-afe-data-6672997cd452c40c7c8109ce",
      varenergitest:
        "https://tds-test.varenergi.no/app/upload-afe-data/upload-afe-data-667c24e1a0de7a081f6127a8",
      varenergiprod:
        "https://tds.varenergi.no/app/upload-afe-data/upload-afe-data-650408a921a0ce5b827b681f",
    },
  },
  {
    name: "Upload iQx Data",
    description: "Upload, inspect, and export iQx data for each each wellbore",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/upload-iqx-data/upload-iqx-file-667298eed452c40c7c81099f",
      varenergidev:
        "https://tds-dev.varenergi.no/app/upload-iqx-data/upload-iqx-file-667298eed452c40c7c81099f",
      varenergitest:
        "https://tds-test.varenergi.no/app/upload-iqx-data/upload-iqx-file-667c248ea0de7a081f61277c",
      varenergiprod:
        "https://tds.varenergi.no/app/upload-iqx-data/upload-iqx-file-6501811175e59933bb643314",
    },
  },
  {
    name: "Appsmith Apps Usage",
    description: "See the usage of appsmith-based apps",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/app-usage/page-views-66729577d452c40c7c81087b",
      varenergidev:
        "https://tds-dev.varenergi.no/app/app-usage/page-views-66729577d452c40c7c81087b",
      varenergitest:
        "https://tds-test.varenergi.no/app/app-usage/page-views-667c2691a0de7a081f61286a",
      varenergiprod:
        "https://tds.varenergi.no/app/apps-usage/page-views-6537a783bf2c4d00fce1cfb5",
    },
  },
  {
    name: "Operations Outlook",
    description:
      "Continuous monitoring and performance benchmarking of drilling activities operated by Vår Energi.",
    roles: ["basic", "extended", "admin"],
    url: {
      local: "/operations-outlook",
      dev: "/operations-outlook",
      varenergidev: "/operations-outlook",
      varenergitest: "/operations-outlook",
    },
  },
  {
    name: "Operations Outlook (Classic)",
    description:
      "Continuous monitoring and performance benchmarking of drilling activities operated by Vår Energi.",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-operations-outlook",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-operations-outlook",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-operations-outlook",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-operations-outlook",
    },
  },
  {
    name: "POA Outlook",
    description:
      "Continuous monitoring and performance benchmarking of drilling activities operated by partners.",
    roles: ["basic", "extended", "admin"],
    url: {
      local: "/poa-outlook",
      dev: "/poa-outlook",
      varenergidev: "/poa-outlook",
      varenergitest: "/poa-outlook",
      varenergiprod: "/poa-outlook",
    },
  },
  {
    name: "POA Outlook (Classic)",
    description:
      "Continuous monitoring and performance benchmarking of drilling activities operated by partners.",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-drilling-report",
      varenergidev:
        "https://tds-dev.varenergi.no/app/redirect/home-66e8029153bdc57cab7cad20?branch=main&environment=production&repoName=var-drilling-report",
      varenergitest:
        "https://tds-test.varenergi.no/app/redirect/home-66e81f5734e6ab664432b748?branch=main&environment=production&repoName=var-drilling-report",
      varenergiprod:
        "https://tds.varenergi.no/app/redirect/home-66e8375b7446933c520807c9?branch=main&environment=production&repoName=var-drilling-report",
    },
  },
  {
    name: "Rig Schedule",
    description:
      "Investigate activities, schedules, and approval statuses for wells across project areas and rigs.",
    roles: ["basic", "extended", "admin"],
    url: {
      local:
        "https://app.powerbi.com/groups/me/apps/3bd981d1-59f0-4906-aae4-91b2abe932b4/reports/24590cd7-2775-447e-8dc2-f4d9dc939d5b/ReportSection9d759b88d886d233d121?ctid=77da4c42-ba77-462b-bb54-7f7ea57bd0a8&experience=power-bi",
      varenergidev:
        "https://app.powerbi.com/groups/me/apps/3bd981d1-59f0-4906-aae4-91b2abe932b4/reports/24590cd7-2775-447e-8dc2-f4d9dc939d5b/ReportSection9d759b88d886d233d121?ctid=77da4c42-ba77-462b-bb54-7f7ea57bd0a8&experience=power-bi",
      varenergitest:
        "https://app.powerbi.com/groups/me/apps/3bd981d1-59f0-4906-aae4-91b2abe932b4/reports/24590cd7-2775-447e-8dc2-f4d9dc939d5b/ReportSection9d759b88d886d233d121?ctid=77da4c42-ba77-462b-bb54-7f7ea57bd0a8&experience=power-bi",
      varenergiprod:
        "https://app.powerbi.com/groups/me/apps/3bd981d1-59f0-4906-aae4-91b2abe932b4/reports/24590cd7-2775-447e-8dc2-f4d9dc939d5b/ReportSection9d759b88d886d233d121?ctid=77da4c42-ba77-462b-bb54-7f7ea57bd0a8&experience=power-bi",
    },
  },
];

export default frontendApps;
