import type { CssVarsThemeOptions } from "@mui/joy/styles";
import { extendTheme } from "@mui/joy/styles";
import { deepmerge } from "@mui/utils";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import { KeyboardArrowDown } from "@mui/icons-material";
import colors from "../tokens/colors";
import breakpoints from "../tokens/breakpoints";
import { selectClasses } from "@mui/joy";

declare module "@mui/joy/styles" {
  interface TypographySystemOverrides {
    "title-xs": true;
    kpi: true;
  }
}

const createTheme = (override?: CssVarsThemeOptions) => {
  const defaultThemeOptions: CssVarsThemeOptions = {
    shadow: {
      xs: "0px 1px 2px 0px rgba(24, 37, 51, 0.10)",
      sm: "0px 3px 4px 0px rgba(24, 37, 51, 0.04)",
      md: "0px 7px 15px 0px rgba(24, 37, 51, 0.05)",
      lg: "0px 12px 25px 0px rgba(24, 37, 51, 0.06)",
      xl: "0px 20px 40px 0px rgba(24, 37, 51, 0.07)",
    },
    colorSchemes: {
      light: {
        palette: {
          //taken from https://www.figma.com/file/HfT3a7QPgAqrOl3NjqJGTn/Prototype?node-id=1%3A515&mode=dev
          neutral: {
            ...colors.grey,
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainColor: "var(--joy-palette-neutral-900)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            plainHoverBg: "var(--joy-palette-neutral-200)",
            plainActiveBg: "var(--joy-palette-neutral-300)",
            softBg: "var(--joy-palette-neutral-200)",
            softActiveBg: "var(--joy-palette-neutral-400)",
            softColor: "var(--joy-palette-neutral-800)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            softHoverBg: "var(--joy-palette-neutral-300)",
            solidActiveBg: "var(--joy-palette-neutral-300)",
            solidBg: "var(--joy-palette-common-white)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidHoverBg: "var(--joy-palette-neutral-100)",
          },
          primary: {
            ...colors.blue,
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            softColor: "var(--joy-palette-primary-800)",
            softDisabledBg: "var(--joy-palette-primary-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
          },
          background: {
            backdrop: "rgba(41, 55, 70, 0.25)",
            body: "var(--joy-palette-neutral-100)",
            surface: "var(--joy-palette-common-white)",
            level1: "var(--joy-palette-neutral-200)",
            level2: "var(--joy-palette-neutral-300)",
            level3: "var(--joy-palette-neutral-400)",
            tooltip: "var(--joy-palette-common-white)",
          },
          text: {
            icon: "var(--joy-palette-neutral-800)",
            secondary: "var(--joy-palette-neutral-600)",
            tertiary: "var(--joy-palette-neutral-500)",
          },
          warning: {
            ...colors.yellow,
            outlinedActiveBg: "var(--joy-palette-warning-200)",
            outlinedBorder: "var(--joy-palette-warning-200)",
            outlinedColor: "var(--joy-palette-warning-800)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-200)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            outlinedHoverBg: "var(--joy-palette-warning-50)",
            plainColor: "var(--joy-palette-warning-800)",
            plainActiveColor: "var(--joy-palette-warning-800)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            plainActiveBg: "var(--joy-palette-warning-200)",
            plainHoverBg: "var(--joy-palette-warning-50)",
            softActiveBg: "var(--joy-palette-warning-200)",
            softActiveColor: "var(--joy-palette-neutral-800)",
            softBg: "var(--joy-palette-warning-50)",
            softColor: "var(--joy-palette-neutral-800)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            softHoverBg: "var(--joy-palette-warning-100)",
            solidActiveBg: "var(--joy-palette-warning-600)",
            solidBg: "var(--joy-palette-warning-400)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            solidHoverBg: "var(--joy-palette-warning-500)",
          },
          success: {
            ...colors.green,
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
          },
          danger: {
            ...colors.red,
            outlinedHoverBg: "var(--joy-palette-danger-100)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
          },
        },
      },
      dark: {
        palette: {
          background: {
            backdrop: "rgba(41, 55, 70, 0.25)",
            body: "#182533",
            surface: "var(--joy-palette-neutral-900)",
            level1: "var(--joy-palette-neutral-800)",
            level2: "var(--joy-palette-neutral-700)",
            level3: "var(--joy-palette-neutral-600)",
            popup: "var(--joy-palette-neutral-900)",
            tooltip: "var(--joy-palette-common-white)",
          },
          neutral: {
            ...colors.grey,
            outlinedBorder: "var(--joy-palette-neutral-600)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-700)",
            plainDisabledColor: "var(--joy-palette-neutral-600)",
            softDisabledColor: "var(--joy-palette-neutral-700)",
            softDisabledBg: "var(--joy-palette-neutral-900)",
            solidActiveBg: "var(--joy-palette-neutral-300)",
            solidBg: "var(--joy-palette-common-white)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledColor: "var(--joy-palette-neutral-700)",
            solidDisabledBg: "var(--joy-palette-neutral-900)",
            solidHoverBg: "var(--joy-palette-neutral-100)",
          },
          text: {
            icon: "var(--joy-palette-neutral-100)",
          },
          danger: {
            ...colors.red,
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedHoverBg: "var(--joy-palette-danger-800)",
            plainDisabledColor: "var(--joy-palette-neutral-700)",
            softDisabledColor: "var(--joy-palette-neutral-700)",
            softDisabledBg: "var(--joy-palette-neutral-900)",
            solidDisabledBg: "var(--joy-palette-neutral-900)",
            solidDisabledColor: "var(--joy-palette-neutral-700)",
          },
          success: {
            ...colors.green,
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-700)",
            plainDisabledColor: "var(--joy-palette-neutral-700)",
            softDisabledColor: "var(--joy-palette-neutral-700)",
            softDisabledBg: "var(--joy-palette-neutral-900)",
            solidDisabledColor: "var(--joy-palette-neutral-700)",
            solidDisabledBg: "var(--joy-palette-neutral-900)",
          },
          warning: {
            ...colors.yellow,
            outlinedActiveBg: "var(--joy-palette-warning-600)",
            outlinedBorder: "var(--joy-palette-warning-500)",
            outlinedColor: "var(--joy-palette-warning-50)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-700)",
            outlinedHoverBg: "var(--joy-palette-warning-500)",
            plainColor: "var(--joy-palette-warning-50)",
            plainActiveColor: "var(--joy-palette-warning-50)",
            plainDisabledColor: "var(--joy-palette-neutral-700)",
            plainActiveBg: "var(--joy-palette-warning-600)",
            plainHoverBg: "var(--joy-palette-warning-500)",
            softActiveBg: "var(--joy-palette-warning-600)",
            softActiveColor: "var(--joy-palette-neutral-800)",
            softBg: "var(--joy-palette-warning-700)",
            softColor: "var(--joy-palette-neutral-800)",
            softDisabledBg: "var(--joy-palette-neutral-900)",
            softDisabledColor: "var(--joy-palette-neutral-700)",
            softHoverBg: "var(--joy-palette-warning-500)",
            solidActiveBg: "var(--joy-palette-warning-600)",
            solidBg: "var(--joy-palette-warning-400)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledBg: "var(--joy-palette-neutral-900)",
            solidDisabledColor: "var(--joy-palette-neutral-700)",
            solidHoverBg: "var(--joy-palette-warning-500)",
          },
          primary: {
            ...colors.blue,
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-600)",
            plainDisabledColor: "var(--joy-palette-neutral-600)",
            softDisabledColor: "var(--joy-palette-neutral-600)",
            solidDisabledColor: "var(--joy-palette-neutral-600)",
          },
        },
      },
    },
    radius: {
      xs: "3px",
      sm: "7px",
      md: "14px",
      lg: "21px",
      xl: "999px",
    },
    spacing: 5,
    fontWeight: { xs: 200, md: 400 },
    fontSize: {
      xl4: "2.5rem",
      xl3: "2rem",
      lg: "1rem",
      md: "0.875rem",
      sm: "0.75rem",
      xs: "0.625rem",
    },
    lineHeight: {
      xs: 1.2,
      sm: 1.2,
      md: 1.5,
      lg: 1.5,
      xl: 1.5,
    },
    typography: {
      h1: {
        letterSpacing: 0,
      },
      h2: {
        letterSpacing: 0,
      },
      h3: {
        letterSpacing: 0,
      },
      h4: {
        letterSpacing: 0,
        lineHeight: 1.2,
      },
      "title-md": {
        lineHeight: 1.2,
      },
      "title-xs": {
        fontSize: "0.625rem",
        lineHeight: 1.2,
        fontWeight: "var(--joy-fontWeight-sm)",
      },
      kpi: {
        fontSize: "5.125rem",
        fontWeight: "var(--joy-fontWeight-xs)",
        lineHeight: 1,
        letterSpacing: -0.5,
      },
    },
    breakpoints: {
      values: breakpoints,
    },
    components: {
      JoyFormHelperText: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: `var(--FormHelperText-color, ${theme.vars.palette.text.secondary})`,
          }),
        },
      },
      JoyDialogTitle: {
        defaultProps: {
          level: "h3",
          sx: { fontWeight: "sm" },
        },
      },
      JoySelect: {
        defaultProps: {
          indicator: <KeyboardArrowDown />,
        },
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            borderRadius: theme.radius.xl,
            padding: theme.spacing(0, 3),
            "--Select-gap": theme.spacing(2),
            gap: theme.spacing(2),
            [`& .${selectClasses.indicator}`]: {
              transition: "0.2s",
              [`&.${selectClasses.expanded}`]: {
                transform: "rotate(-180deg)",
              },
            },
            "& *": {
              margin: 0,
            },
            "& .MuiSelect-startDecorator, & .MuiSelect-endDecorator": {
              width: "1.5rem",
              height: "1.5rem",
              color: "inherit",
              margin: 0,
              "& svg": {
                width: "100%",
                height: "100%",
              },
            },
            "& .MuiSelect-indicator": {
              marginInlineStart: 0,
            },
            ...(ownerState.size === "sm" && {
              minHeight: "2rem",
              padding: theme.spacing(0, 2),
            }),
            ...(ownerState.size === "md" && {
              minHeight: "2.5rem",
            }),
            ...(ownerState.size === "lg" && {
              minHeight: "3rem",
            }),
          }),

          listbox: ({ theme, ownerState }) => ({
            "& .MuiOption-root": {
              gap: theme.spacing(2),
              ...(ownerState.size === "sm" && {
                padding: theme.spacing(1, 2),
              }),
              ...(ownerState.size === "md" && {
                padding: theme.spacing(1, 3),
              }),
              ...(ownerState.size === "lg" && {
                padding: theme.spacing(2, 4),
              }),
            },
          }),
        },
      },
      JoyCheckbox: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            gap: theme.spacing(1),
            ...(["lg", "md"].includes(ownerState.size || "") && {
              gap: theme.spacing(2),
            }),
          }),
        },
      },
      JoyAutocomplete: {
        defaultProps: {
          popupIcon: <KeyboardArrowDown />,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.lg,
          }),
          popupIndicator: ({ theme }) => ({
            borderRadius: theme.radius.lg,
          }),
          startDecorator: ({ theme, ownerState }) => ({
            ...(ownerState.size === "sm" && {
              marginLeft: theme.spacing(0.5),
            }),
          }),
        },
      },
      JoyButton: {
        styleOverrides: {
          root: ({ theme, ownerState }) => [
            {
              "--Button-radius": theme.radius.xl,
              "--Button-gap": theme.spacing(ownerState.size === "sm" ? 1 : 2),
              "--Button-paddingBlock": theme.spacing(
                ownerState.size === "lg" ? 2 : 1
              ),
              "--Button-paddingInline": theme.spacing(
                ownerState.size === "lg" ? 4 : 3
              ),
              lineHeight: theme.vars.lineHeight.sm,
            },
            ownerState.size && {
              fontSize: theme.typography[`title-${ownerState.size}`].fontSize,
              ...(ownerState.size === "sm" && {
                "--Button-minHeight": "2rem",
              }),
              ...(ownerState.size === "md" && {
                "--Button-minHeight": "2.5rem",
              }),
              ...(ownerState.size === "lg" && {
                "--Button-minHeight": "3rem",
              }),
            },
            ownerState.variant === "solid" && {
              boxShadow: theme.vars.shadow.xs,
            },
          ],
        },
      },
      JoyRadio: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            gap: theme.spacing(1),
            ...(ownerState.size === "md" && {
              gap: theme.spacing(2),
            }),
            ...(ownerState.size === "lg" && {
              gap: theme.spacing(2),
            }),
          }),
        },
      },
      JoyIconButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.xl,
          }),
        },
      },
      JoyMenuButton: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            borderRadius: theme.radius.lg,
            "--Icon-fontSize": "0.875rem",
            ...(ownerState.size === "sm" && {
              height: "2rem",
            }),
            ...(ownerState.size === "md" && {
              height: "2.5rem",
            }),
            ...(ownerState.size === "lg" && {
              height: "3rem",
            }),
            "& .MuiBadge-badge": {
              "--Badge-ring": "transparent",
            },
          }),
        },
      },
      JoyTable: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            "--TableCell-paddingX": theme.spacing(2),
            "--unstable_TableCell-height": "none",
            "--unstable_actionRadius": 0,
            backgroundColor: "transparent",
            color: "var(--joy-palette-text-primary)",
            "tbody tr td:first-child": {
              borderTopLeftRadius: theme.radius.sm,
              borderBottomLeftRadius: theme.radius.sm,
            },
            "tbody tr td:last-child": {
              borderTopRightRadius: theme.radius.sm,
              borderBottomRightRadius: theme.radius.sm,
            },
            "border-spacing": theme.spacing(0, 1),
            "tr td": {
              p: {
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 4,
                textOverflow: "ellipsis",
              },
              button: {
                ...(ownerState.variant === "soft" && {
                  backgroundColor: "var(--joy-palette-background-surface)",
                }),
                ...(ownerState.variant === "solid" && {
                  backgroundColor: "var(--joy-palette-background-body)",
                }),
                ...((ownerState.variant === "soft" ||
                  ownerState.variant === "solid") && {
                  color: "var(--joy-palette-text-icon)",
                  "&:hover": {
                    backgroundColor: "var(--joy-palette-neutral-softHoverBg)",
                  },
                  "&:active": {
                    backgroundColor: "var(--joy-palette-neutral-softActiveBg)",
                  },
                  "&:disabled": {
                    backgroundColor:
                      "var(--joy-palette-neutral-softDisabledBg)",
                    color: "var(--joy-palette-text-icon)",
                    opacity: 0.6,
                  },
                }),
              },
            },
            "tr th": {
              color: "var(--joy-palette-text-primary)",
            },
            "tr.danger td, tr.warning td": {
              button: {
                "&:hover": {
                  backgroundColor: colors.alphaWhite[300],
                },
                "&:active": {
                  backgroundColor: colors.alphaWhite[300],
                },
                "&:disabled": {
                  backgroundColor: colors.alphaWhite[100],
                  opacity: 0.6,
                },
              },
            },
            "tr.danger td": {
              background: "var(--joy-palette-danger-solidBg)",
              color: "var(--joy-palette-danger-solidColor)",
              button: {
                backgroundColor: colors.alphaWhite[200],
                color: "var(--joy-palette-neutral-solidBg)",
              },
            },
            "tr.warning td": {
              backgroundColor: "var(--joy-palette-warning-solidBg)",
              color: "var(--joy-palette-warning-solidColor)",
              button: {
                backgroundColor: colors.alphaBlack[50],
                color: "var(--joy-palette-neutral-solidColor)",
              },
            },
            overflow: "auto",
          }),
        },
      },
      JoyInput: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            "--Input-radius": theme.vars.radius.xl,
            "--Input-gap": theme.spacing(ownerState.size === "sm" ? 2 : 3),
            "&:not([data-skip-inverted-colors])": {
              "--Input-focusedHighlight":
                theme.vars.palette[ownerState.color || "primary"][500],
            },
            lineHeight: theme.vars.lineHeight.sm,
            ...(ownerState.size === "sm" && {
              minHeight: "2rem",
              padding: theme.spacing(1, 2),
            }),
            ...(ownerState.size === "md" && {
              minHeight: "2.5rem",
              padding: theme.spacing(2, 3),
            }),
            ...(ownerState.size === "lg" && {
              minHeight: "3rem",
              padding: theme.spacing(2, 3),
            }),
            ...(ownerState.variant === "soft" && {
              boxShadow: "none",
            }),
          }),
          endDecorator: () => ({
            color: "inherit",
          }),
          startDecorator: ({ ownerState }) => ({
            color: "inherit",
            ...(ownerState.size === "sm" && {
              "--Icon-fontSize": "0.875rem",
            }),
            ...(ownerState.size === "md" && {
              "--Icon-fontSize": "1rem",
            }),
            ...(ownerState.size === "lg" && {
              "--Icon-fontSize": "1.25rem",
            }),
          }),
        },
      },
      JoyFormLabel: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.vars.palette.text.tertiary,
            fontSize: theme.vars.fontSize.xs,
            marginBottom: theme.spacing(1),
            fontWeight: theme.vars.fontWeight.sm,
          }),
        },
      },
      JoyModalClose: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.xl,
          }),
        },
      },
      JoySlider: {
        styleOverrides: {
          valueLabel: {
            color: "var(--joy-palette-neutral-900)",
          },
          thumb: {
            "&.Mui-focusVisible, &:focus-visible": { outline: "none" },
            "&::before": {
              borderWidth: "4px",
            },
          },
          markLabel: { display: "none" },
        },
      },
      JoySwitch: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            "--Switch-gap": "0.5rem",
            ...(ownerState.size === "sm" && {
              "--Switch-trackHeight": "1.25rem",
              "--Switch-thumbSize": "0.875rem",
              "--Switch-trackWidth": "2.125rem",
            }),
            ...(ownerState.size === "md" && {
              "--Switch-trackHeight": "1.5rem",
              "--Switch-thumbSize": "1.125rem",
              "--Switch-trackWidth": "2.625rem",
            }),
            ...(ownerState.size === "lg" && {
              "--Switch-trackHeight": "2rem",
              "--Switch-thumbSize": "1.625rem",
              "--Switch-trackWidth": "3.5rem",
            }),
          }),
        },
      },
    },
  };

  return extendTheme(deepmerge(defaultThemeOptions, override));
};

export default createTheme;
