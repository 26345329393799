import { type DeployConfigFrontend } from "library-frontend-utils/infra";

const config: DeployConfigFrontend = {
  local: {
    authenticationApp: "digitalSolutionsPlatformDev",
    backendUrl: "http://localhost:3000",
  },
  dev: {
    authenticationApp: "digitalSolutionsPlatformDev",
    backendUrl: "/functions/duty-handover",
  },
  prod: false,
  t60dev: false,
  t60prod: false,
  varenergidev: {
    authenticationApp: "odpDev",
    backendUrl: "/functions/duty-handover",
  },
  varenergitest: {
    authenticationApp: "odpDev",
    backendUrl: "/functions/duty-handover",
  },
  varenergiprod: {
    authenticationApp: "odpProd",
    backendUrl: "/functions/duty-handover",
  },
};

export default config;
