export const stackNames = [
  "dev",
  "prod",
  "t60dev",
  "t60prod",
  "varenergidev",
  "varenergitest",
  "varenergiprod",
] as const;

export type StackName = (typeof stackNames)[number];
